.components {
	padding-top: 85px;
	padding-bottom: 102px;
	position: relative;
	background: url(../img/bg.png) no-repeat 50% 0;
    background-color: #e4f3f9;
    background-size: cover;
    text-align: center;
    color: #202020;

	&::before {
		content: "";
    position: absolute;
    left: 50%;
    background-size: cover;
    top: -1px;
    height: 20px;
    width: 2660px;
    background: url(../img/after-bg.svg) no-repeat center;
    z-index: 0;
    transform: translate(-50%) rotate(-180deg) ;
	}

	&::after {
		content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    bottom: -1px;
    height: 20px;
    width: 2660px;
    background: url(../img/after-bg.svg) no-repeat center;
    z-index: 0;
	}

	.title {
		padding: 0 150px;
	}
	&__subtitle {
		font-size: 22px;
		display: block;
		margin-top: 14px;
		font-weight: 500;
		color: #444444;
	}
	&__desc {
		margin-top: 20px;
		padding-top: 36px;
		position: relative;
		z-index: 1;
		&::before {
			content: '';
			display: block;
			position: absolute;
			height: 70px;
			width: 101px;
			background-image: url(../img/components__flag.png);
			background-size: contain;
			background-repeat: no-repeat;
			left: 51%;
			top: 0;
			transform: translateX(-50%);
			z-index: -1;

		}
		h3 {
			
			background-color: #fff;
			max-width: 777px;
			margin-left: auto;
			margin-right: auto;
			font-size: 24px;
			padding: 8px 20px;
			border-radius: 45px;
			font-weight: 500;
		}
		p {
			margin-top: 30px;
			font-size: 18px;
			line-height: 1.56;
			padding: 0 120px;
		}
	}
	&__list-1 {
			display: flex;
			justify-content: space-around;
			margin-top: 32px;
			padding: 0 62px;
			padding-top: 39px;
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;

				height: 1px;
				background-color: rgba(158, 188, 198, 0.58);
				top: 0;
				left: 0;
			}
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 19px;
				height: 19px;
				background-color: #e2f3fa;
				border: 1.5px solid rgba(158, 188, 198, 0.58);
				border-left: none;
				border-top: none;
				transform: translateX(-50%) scaleY(0.7) rotate(45deg) ;
				top: -10px;
				left: 50%;
			}
			.components__item:nth-of-type(2) {
				position: relative;
				&::before,
				&::after {
					content: '';
				    display: block;
				    position: absolute;
				    width: 78px;
				    height: 2px;
				    background-image: repeating-linear-gradient(to right, #9ebbc6 0px, #9ebbc6 4px, rgba(255, 255, 255, 0) 4px, rgba(255, 255, 255, 0) 10px);
				    top: 126px;
				}
				&::before {
					left: -109px;
				}
				&::after {
    				right: -109px;
				}
			}
	}

	&__img-block {
		width: 198px;
		height: 198px;
		background-color: rgba(255, 255, 255, 0.39);
		border-radius: 50%;
		position: relative;
		box-shadow: 0px 5px 21px rgba(181, 205, 223, 0.77);

		img {
			position: absolute;
			max-width: 185px;

		}
	}
	&__img-1 {
		left: 3px;
		bottom: -10px;
	}
	&__img-2 {
		left: 16px;
		bottom: -11px;
	}
	&__img-3 {
		left: 22px;
		bottom: -12px;
	}
	&__caption {
		margin-top: 32px;
		font-size: 21px;
		line-height: 1.2;
		font-weight: bold;

	}


	&__title-list {
		font-size: 23px;
		font-weight: 500;
		margin-top: 44px;
	}
	&__list-2 {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 272px;
			height: 2px;
    		background-image: repeating-linear-gradient(to right, #9ebbc6 0px, #9ebbc6 4px, rgba(255, 255, 255, 0) 4px, rgba(255, 255, 255, 0) 10px);
		    top: 70px;
    		left: 244px;
			}
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 272px;
			height: 2px;
    		background-image: repeating-linear-gradient(to right, #9ebbc6 0px, #9ebbc6 4px, rgba(255, 255, 255, 0) 4px, rgba(255, 255, 255, 0) 10px);
		    top: 70px;
    		right: 244px;
			}

		.components__item {
			max-width: 370px;
			padding-top: 50px;
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				background-color: #aac6d0;
				height: 40px;
				width: 2px;
				top: 1px;
				left: 50%;
			}
			&::after {
				content: '';
				display: block;
				position: absolute;
				height: 14px;
				width: 14px;
				border: 2px solid #aac6d0;
				border-left: none;
				border-top: none;
				
				top: 21px;
				left: 50%;
			}
		}
	}

	&__item-1 {
		&::before {
			transform: translateX(28px) rotate(40deg) ;
		}
		&::after {
			transform: translateX(14px) rotate(84deg);
		}
	}
	&__item-2 {
		&::before {
			transform: translateX(-1px);
		}
		&::after {
			transform: translateX(-8px) translateY(1px) rotate(45deg);
		}
	}
	&__item-3 {
		&::before {
			transform: translateX(-32px) rotate(-40deg) ;
		}
		&::after {
			transform: translateX(-32px) rotate(3deg);
		}
	}
	
	&__what-do {
		font-size: 18px;
		line-height: 1.56;
		color: #444;
		padding-top: 55px;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			height: 42px;
			width: 42px;
			background-color: #82aa34;
			border-radius: 50%;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			box-shadow: 0px 3px 7px rgba(176, 186, 104, 0.15);
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 19px;
		    height: 10px;
		    border: 4px solid #fff;
		    border-right: none;
		    border-top: none;
		    left: 50%;
		    top: 11px;
		    
		    transform: translateX(-50%) rotate(-45deg);
		}
		b {
			display: block;
			font-size: 20px;
			font-weight: 500;
			color: #202020;
			margin-bottom: 9px;
			
		}
	}

	&__conclusion {
		margin-top: 38px;
		padding: 9px 30px;
		padding-bottom: 12px;
		background-color: transparent;
		border-radius: 85px;
		border: 3px solid #c3dce6;
		font-size: 24px;
		line-height: 1.22;
		font-weight: 500;
	}

}

@media screen and (max-width: 1190px) {
	.components {
		padding-top: 64px;
		padding-bottom: 80px;
		&__subtitle {
			font-size: 21px;
			margin-top: 0;
		}
		&__desc {
			h3 {
				font-size: 22px;
				max-width: 700px;
				padding-right: 10px;
				padding-left: 10px;
			}
			p {
				margin-top: 20px;
				padding: 0 30px;
			}
		}
		&__list-1 {
			margin-top: 22px;
			padding-top: 36px;
			.components__item:nth-of-type(2) {
				&::before,
				&::after {
					width: 70px;
					top: 107px;
				}
				&::before {
					left: -83px;
				}
				&::after {
					right: -83px;
				}
			}
		}
		&__title-list {
			margin-top: 35px;
			font-size: 22px;
		}
		&__list-2 {
			margin-top: 8px;
			&::before {
				width: 215px;
				left: 229px;
			}
			&::after {
				width: 215px;
				right: 229px;
			}
			.components__item {
				max-width: 410px;
			}
		}
		&__what-do {
			font-size: 17px;
			padding-top: 48px;
			b {
				margin-bottom: 0px;
			}
		}

		&__conclusion {
			font-size: 22px;
			margin-top: 32px;
		}
	}
}


@media (max-width: 1023px) {
	.components {
		padding-top: 40px;
		padding-bottom: 40px;
		&::before,
		&::after {
			display: none;
		}
		.title {
			padding: 0;
		}

		&__desc {
			h3 {
				font-size: 20px;
				width: 640px;
			}
			p {
				padding: 0;
				font-size: 17px;
			}
		}
		&__list-1 {
			// justify-content: space-between;
			padding-left: 0;
			padding-right: 0;
			.components__item:nth-of-type(2) {
				&::before,
				&::after {
					width: 70px;
					top: 94px;
				}
				&::before {
					left: -78px;
				}
				&::after {
					right: -81px;
				}
			}
		}
		&__img-block {
			width: 160px;
			height: 160px;
			img {
				width: 130px;
			}
		}
		&__caption {
			margin-top: 18px;
			font-size: 19px;
		}

		&__title-list {
			margin-top: 20px;
			font-size: 20px;
		}
		&__list-2 {
			margin-top: 4px;
			&::before {
				display: none;
			}
			&::after {
				display: none;
			}
			.components__item {
				padding-top: 32px;
				&::before {
					height: 26px;
				}
				&::after {
					height: 10px;
					width: 10px;
					top: 12px;
				}
			}
		}
		&__item-1 {
			&::after {
				transform: translateX(19px) rotate(84deg);
			}
		}
		&__item-2 {
			&::before {
				transform: translateX(-1px);
			}
			&::after {
				transform: translateX(-6px) rotate(45deg);
			}
		}
		&__item-3 {
			&::before {
				transform: translateX(-28px) rotate(-40deg);
			}
			&::after {
				transform: translateX(-30px) rotate(3deg);
			}
		}
		&__what-do {
			padding-top: 35px;
			font-size: 16px;
			&::before {
				width: 34px;
				height: 34px;
			}
			&::after {
				width: 17px;
				height: 8px;
				top: 10px;
			}
			b {
				font-size: 18px;
			}
		}
		&__conclusion {
			font-size: 19px;
			border-radius: 25px;
			padding: 6px 20px;
		}
	}
}


@media (max-width: 767px) {
	.components {
		padding-top: 30px;
		padding-bottom: 30px;
		&__subtitle {
			font-size: 19px;
		}
		&__desc {
			margin-top: 10px;

			h3 {
				font-size: 18px;
				width: 600px;
				border-radius: 16px;
			}
			p {
				font-size: 16px;
				line-height: 1.3;
				margin-top: 16px;
			}
		}
		&__list-1 {
			margin-top: 18px;
			padding-top: 32px;
			.components__item:nth-of-type(2) {
				&::before,
				&::after {
					display: none;
				}
			}
		}
		&__title-list {
			font-size: 19px;
		}
		&__list-2 {
			flex-wrap: wrap;
			.components__item {
				padding-top: 0px;
				margin-top: 10px;
				max-width: 306px;
				&::before,
				&::after {
					display: none;
				}
			}
			.components__item-3 {
				margin: auto;
				margin-top: 10px;
				max-width: none;
			}
		}
		&__conclusion {
			margin-top: 18px;
		}
		
	}
}


@media screen and (max-width: 639px) {
	.components {
		padding-top: 25px;
		padding-bottom: 25px;
		&__subtitle {
			line-height: 1.2;
			margin-top: 10px;
		}
		&__desc {
			h3 {
				font-size: 16px;
				width: 100%;
				max-width: 340px;
			}
		}
		&__list-1 {
			padding-top: 28px;
			flex-wrap: wrap;
			.components__item {
				padding: 0 5px;
				margin-bottom: 12px;
			}
		}
		&__caption {
			font-size: 18px;
		}
		&__title-list {
			font-size: 18px;
		}
		&__list-2 {
			.components__item {
				max-width: none;
				width: 100%;
			}
		}
		&__what-do {
			line-height: 1.2;
			padding-top: 32px;
			&::before {
				height: 28px;
				width: 28px;
			}
			&::after {
				width: 15px;
				height: 7px;
				top: 7px;
			}
		}
		&__conclusion {
			font-size: 17px;
			padding: 6px 10px;
		}
	}
}