.doc {
    padding-top: 127px;
    padding-bottom: 33px;

    position: relative;
    overflow: hidden;
    z-index: 2;

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-145.5%);
        bottom: 8px;
        background: url(../img/doctor-bg.png) no-repeat center;
        width: 503px;
        height: 604px;
    }

    &-content {
        margin-top: 65px;
        width: 583px;
        margin-left: 569px;

        &__title {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        &__subtitle {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            color: #444;
            margin-bottom: 30px;

            &-name {
                font-size: 22px;
                font-weight: 500;
                display: block;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__signature {
            position: relative;
            margin-left: 45px;
            margin-top: -12px;
        }
    }

    &__img {
        position: absolute;
        bottom: 6px;
        left: 50%;
        transform: translate(-105%) rotate(-0.1deg);
        z-index: 3;

        &-name {
            position: absolute;
            left: 50%;
            top: 50%;
            color: #c6cad2;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            transform: translate(-179%, 221%) skew(13deg, -13deg);
        }
    }
}

.mnone {
    margin: 0;
}
@-moz-document url-prefix() {
  .doc__img{
    bottom: 5.5px;
  }
}
@media screen and (max-width: 1190px) {
    .doc {
        &-content {
            margin-top: 45px;
            width: 535px;
            margin-left: 467px;
            &__title{
                font-size: 20px;
                margin-bottom: 20px;
            }
            &__subtitle{
                font-size: 17px;
                line-height: 27px;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .doc {

        &:before{
            display: none;
        }
        &-content {
            margin-top: 30px;
            width: 100%;
            margin-left: 0px;
            padding-top: 450px;
            &__title{
                font-size: 20px;
                margin-bottom: 10px;
            }
            &__subtitle{
                font-size: 17px;
                line-height: 27px;
                margin-bottom: 10px;
                br{
                    display: none;
                }
            }
            &__signature{
                margin-top: 0;
            }
        }
        &__img{
            top: 150px;
           transform: translate(-50%,0);
           bottom: auto;
            
           width: 350px;
           z-index: 4;
           &:after{
                content: "";
                position: absolute;
                width: 350px;
                height: 4px;
                background-color: #e4e4e4;
                bottom: 4px;
                left: 0;
           }
           &-name{
            transform: translate(-161%, 184%) skew(13deg, -13deg);
            font-size: 9px;
            line-height: normal;
            z-index: 8;
           }
           img{
            width: 100%;
            
           }
        }
    }
}

@media (max-width: 767px) {
    .doc {
        .title{
            br{
                display: none;
            }
        }
      
        &-content {
           padding-top: 300px;
            &__title{
               font-size: 19px;
               margin-bottom: 7px;
            }
            &__subtitle{
              font-size: 16px;
              line-height: normal; 
            }
        }
        &__img{
         top: 110px;
           transform: translate(-50%,0);
          width: 250px;
          &:after{
            width: 100%;
          }
           &-name{
           transform: translate(-150%, 151%) skew(13deg, -13deg);
            font-size: 7px;
           }
           img{
          
            
           }
        }
    }
}

@media screen and (max-width: 639px) {
    .doc {
        .title{
           
        }
        
        &-content {
           padding-top: 0px;
           margin-top: 15px;
            &__title{
              
            }
            &__subtitle{
              
            }
        }
        &__img{
         
           display: none;
          
           
        }
    }
}