.orus {
    padding-top: 56px;
    padding-bottom: 71px;

    &-top {
        margin-top: 89px;
        display: flex;
        justify-content: flex-end;

        &-boximg {
            position: relative;
            width: 34%;

            &__prod {
                position: absolute;
                width: 196px;
                height: 279px;
                top: -38px;
                left: -111px;
            }
        }

        &-instruction {
            width: 50%;

            &__small {
                font-size: 20px;
                font-weight: 500;
                color: #202020;
                line-height: 28px;
            }

            &__big {
                width: 470px;
                background-color: #e8f3da;
                color: #202020;
                font-size: 24px;
                font-weight: 500;
                line-height: 26px;
                text-align: center;
                padding: 15px 28px;
                border-radius: 40px;
                margin-top: 38px;
            }
        }
    }

    &-line {
        background-color: #9ebcc6;
        opacity: 0.58;
        height: 1px;
        width: 100%;
        position: relative;
        margin-top: 98px;
        margin-bottom: 60px;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%);
            width: 0;
height: 0;
border-style: solid;
border-width: 10px 14px 0 14px;
border-color: #9ebcc6 transparent transparent transparent;
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: -1px;
            transform: translate(-50%);
            width: 0;
height: 0;
border-style: solid;
border-width: 10px 14px 0 14px;
border-color: #ffffff transparent transparent transparent;

        }
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 62px;

        &-box {
            width: 367px;
            position: relative;

            &__img {
                position: relative;
                height: 217px;

                &:after {
                    content: "1";
                    position: absolute;
                    left: 50%;
                    bottom: -24px;
                    -webkit-transform: translate(-50%);
                    -ms-transform: translate(-50%);
                    transform: translate(-50%);
                    color: #2a3d70;
                    font-size: 57px;
                    font-weight: 700;
                    width: 51px;
                    height: 51px;
                    background-color: #ffd36e;
                    opacity: 0.93;
                    border-radius: 50%;
                    text-align: center;
                }
            }


            &__text {
                color: #202020;
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                margin-top: 30px;
                text-align: center;
            }
        }

        &-box1 {
            background: url(../img/order-bg1.png) no-repeat top center;

            &__img {

                img {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%);
                    width: 191px;
                }

                &:before {
                    content: "";
                    width: 64px;
                    height: 55px;
                    position: absolute;
                    top: 35px;
                    left: 50%;
                    transform: translate(35%);
                    background: url(../img/hand.png);
                    z-index: 5;
                }
            }
        }

        &-box2 {
            background: url(../img/order-bg2.png) no-repeat top center;

            &__img {
                &:after {
                    content: "2";
                }

                img {
                    position: absolute;
                    left: 50%;
                    bottom: 9px;
                    transform: translate(-50%);

                }
            }
        }

        &-box3 {
            background: url(../img/order-bg3.png) no-repeat top center;

            &__img {
                &:after {
                    content: "3";
                }

                img {
                    position: absolute;
                    left: 50%;
                    bottom: 7px;
                    transform: translate(-60%);

                }
            }
        }
    }

    .prod-name {
        position: absolute;
        left: 50%;
        top: 50%;
        color: #c1b095;
        font-size: 24px;
        transform: translate(-50%, -236%) skew(20deg, -20deg);
    }

    &-lock {
        width: 750px;
        height: 59px;
        background-color: #e8f3da;
        padding: 16px 28px;
        color: #202020;
        font-size: 24px;
        font-weight: 500;
        line-height: 26px;
        border-radius: 35px;
        display: block;
        margin: 54px auto 0;
    }
}

@media screen and (max-width: 1190px) {
    .orus {
        &-top {
            margin-top: 60px;
            align-items: center;

            &-boximg {
                margin-right: 20px;

                &__use {
                    width: 100%;
                }

                &__prod {
                    height: auto;
                    width: 170px;
                    left: -70px;
                }
            }

            &-instruction {
                &__small {
                    font-size: 19px;
                    line-height: normal;
                }

                &__big {
                    width: 430px;
                    font-size: 21px;
                    margin-top: 25px;
                }
            }
        }

        &-line {
            margin-top: 70px;
            margin-bottom: 45px;
        }

        &-bottom {
            margin-top: 50px;

            &-box {
                width: 320px;
                border-radius: 5px;

                &__text {
                    font-size: 18px;
                    line-height: normal;
                    margin-top: 35px;
                }
            }

            &-box3 {
                &__img {
                    img {
                        transform: translate(-52.5%);
                    }
                }
            }
        }

        .prod-name {
            transform: translate(-27%, -239%) skew(20deg, -20deg);
        }

        &-lock {
            margin-top: 40px;
            text-align: center;
            width: 710px;
            font-size: 22px;
        }
    }
}

@media (max-width: 1023px) {
    .orus {
        &-top {
            margin-top: 45px;


            &-boximg {


                &__use {}

                &__prod {
                    width: 142px;
                    left: -59px;
                }
            }

            &-instruction {
                width: 52%;

                &__small {
                    font-size: 18px;

                    br {
                        display: none;
                    }
                }

                &__big {
                    width: 365px;
                    font-size: 18px;
                    margin-top: 10px;
                }
            }
        }

        &-line {
            margin-top: 56px;
            margin-bottom: 35px;
        }

        &-bottom {
            margin-top: 35px;

            &-box {
                width: 240px;

                &__text {
                    font-size: 17px;
                    line-height: normal;
                    margin-top: 30px;
                }

                &__img {
                    img {
                        width: 80%;
                    }

                    &:after {
                        bottom: 9px;
                        font-size: 44px;
                        width: 42px;
                        height: 42px;
                        bottom: -15px;
                    }
                }
            }

            &-box3 {
                &__img {
                    img {
                        width: 95%;
                        bottom: 28px;
                    }
                }
            }
        }

        .prod-name {
            font-size: 17px;
        }

        &-lock {
            margin-top: 30px;
            width: 590px;
            font-size: 18px;
        }
    }
}

@media (max-width: 767px) {
    .orus {
        &-top {
            flex-direction: column;
            margin-top: 35px;

            &-boximg {
                width: 52%;

                &__use {
                    display: none;
                }

                &__prod {
                    position: initial;
                    display: block;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            &-instruction {
                width: 100%;
                margin-top: 25px;

                &__small {
                    text-align: center;

                    br {}
                }

                &__big {
                    display: block;
                    margin: 18px auto 0;
                    max-width: 100%;
                    line-height: normal;
                }
            }
        }

        &-line {
            margin-top: 42px;
            margin-bottom: 35px;
        }

        &-bottom {

            margin-top: 30px;
            flex-wrap: wrap;
            justify-content: center;

            &-box {
                margin: 10px;

                &__text {}

                &__img {
                    img {}

                    &:after {}
                }
            }

            &-box3 {
                &__img {
                    img {}
                }
            }
        }

        .prod-name {}

        &-lock {
            line-height: normal;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (max-width: 639px) {
    .orus {
        &-top {


            &-boximg {
                width: 305px;
                max-width: 100%;

                &__use {
                    display: none;
                }

                &__prod {
                    position: initial;
                    display: block;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            &-instruction {


                &__small {
                    font-size: 17px;


                }

                &__big {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }

        &-line {}

        &-bottom {


            &-box {
								margin-top: 15px;
								margin-bottom: 15px;

                &__text {}

                &__img {
                    img {}

                    &:after {}
                }
            }

            &-box3 {
                &__img {
                    img {}
                }
            }
        }

        .prod-name {}

        &-lock {
            width: 100%;
						height: auto;
						margin-top: 20px;
        }
    }
}

@media screen and (max-width: 479px) {}