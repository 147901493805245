.newlife {
    padding-top: 97px;
    padding-bottom: 150px;
    background: url('../img/bg.png') no-repeat 50% 0;
    background-color: #e4f3f9;
    position: relative;
    background-size: cover;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        background-size: cover;
        top: -1px;
        height: 20px;
        width: 2660px;
        background: url(../img/after-bg.svg) no-repeat center;
        z-index: -1;
        transform: translate(-50%) rotate(-180deg);
    }

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
        height: 20px;
        width: 2660px;
        background: url(../img/after-bg.svg) no-repeat center;
        z-index: -1;
    }

    &-content {
        margin-top: 38px;
        display: flex;
        flex-wrap: wrap;

        &-box {
            width: 33.33333%;
            margin-bottom: 23px;

            &__img {
                position: relative;

                &:after {
                    content: "1";
                    position: absolute;
                    left: 50%;
                    bottom: 24px;
                    transform: translate(-50%);
                    color: #2a3d70;
                    font-size: 57px;
                    font-weight: 700;
                    width: 51px;
                    height: 51px;
                    background-color: #ffd36e;
                    opacity: 0.93;
                    border-radius: 50%;
                    text-align: center;
                }
            }

            &__img2 {
                &:after {
                    content: "2";

                }
            }

            &__img3 {
                &:after {
                    content: "3";

                }
            }

            &__img4 {
                &:after {
                    content: "4";

                }
            }

            &__img5 {
                &:after {
                    content: "5";

                }
            }

            &__img6 {
                &:after {
                    content: "6";

                }
            }

            &__text {
                color: #444444;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                text-align: center;
            }
        }
    }

    &-btitle {
        text-align: center;
        color: #202020;
        font-size: 23px;
        font-weight: 500;
        line-height: 26px;
        margin-top: 25px;
    }

    &-bsubtitle {
        text-align: center;
        color: #202020;
        font-size: 24px;
        font-weight: 500;
        line-height: 26px;
        width: 370px;
        border: 3px solid #c3dce6;
        border-radius: 30px;
        padding: 15px 0;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }

    &-form {
        width: 100%;
        height: 139px;
        box-shadow: -2px 5px 21px rgba(181, 205, 223, 0.77);
        background-color: #ffffff;
        display: flex;
        align-items: center;
        margin-top: 80px;
        border-radius: 5px;
        position: relative;
        margin-bottom: -200px;

        &-pack {
            width: 117px;
            height: 166px;
            margin-left: 44px;
            position: relative;
            margin-top: -45px;

            img {
                width: 100%;
            }
        }

        &-price {
            display: flex;
            margin-left: 13px;
        }

        &-box {
            margin-left: 47px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .form__label {
                margin-bottom: 0;
            }

            .form__label-tel {
                margin-left: 20px;
                margin-right: 30px;
            }

            .btn {
                margin-top: 0;
            }
        }
    }

    &-oldprice {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__title {
            color: #444444;
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
        }

        &__value {
            color: #3e3e3e;
            font-size: 37px;
            font-weight: 500;
            line-height: 26px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 22px;
                background-color: #eb5454;
                height: 2px;
                width: 85px;
                transform: rotate(15deg) translate(-50%);
            }
        }

        &__current {
            color: #444444;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }

    &-newprice {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 24px;
        padding-left: 26px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 2px;
            width: 1px;
            height: 44px;
            background-color: #cacaca;
        }

        &__title {
            color: #444444;
            font-size: 13px;
            font-weight: 400;
            line-height: 26px;
        }

        &__value {
            color: #87b136;
            font-size: 40px;
            font-weight: 900;
            line-height: 22px;
        }

        &__current {
            color: #444444;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }
}
.snoska{
    margin-top: 23px;
    text-align: center;
    .form__footnote{
        margin-top: 5px !important;
    }
}
@media (max-width: 1190px) and (min-width: 1024px){
	.newlife{
		padding-bottom: 80px !important;
	}
	.doc{
		padding-top: 40px !important;
	}
}
@media screen and (max-width: 1190px) {
    .newlife {
			
        &:before{
            top: -2px;
        }
        &:after{
            bottom: -2px;
        }
        &-content {
            margin-top: 23px;

            &-box {
                margin-bottom: 15px;

                &__img {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }

                &__text {
                    font-size: 17px;
                    line-height: normal;
                    padding: 0 10px;

                    br {
                        display: none;
                    }
                }
            }
        }

        &-btitle {
            font-size: 21px;
            margin-top: 10px;
            line-height: normal;
        }

        &-bsubtitle {
            width: 340px;
            font-size: 22px;
            line-height: normal;
            border: 3px solid #e4e4e4;
        }
        &-form{
            width: 555px;
            flex-direction: column;
            height: auto;
            margin-bottom: 0;
            padding-bottom: 30px;
            margin-right: auto;
            margin-left: auto;
            margin-top: 30px;
            position: relative;
            padding-left: 265px;
            padding-top: 30px;
            &-price{
                margin-left: 0;
            }
            &-pack{
                margin-left: 0;
                margin-bottom: 10px;
                margin-top: 0;
                position: absolute;
                left: 30px;
                top: 50%;
                transform: translate(0,-50%);
                width: 250px;
                height: auto
            }
            &-box{
                flex-direction: column;
                margin-top: 15px;
                margin-left: 0;
            }
        }
        .form__label{
            margin-bottom: 20px;
        }
        .form__label-tel{
            margin-right: auto;
            margin-left: auto;
        }
    }
    .snoska{
    margin-top: 0px;
  
}
}


@media (max-width: 1023px) {
    .newlife {
        &:before,&:after{
            display: none;
        }
        &-content {
            margin-top: 18px;

            &-box {

                &__img {
                    &:after {

                        bottom: 9px;
                        font-size: 44px;

                        width: 42px;
                        height: 42px;

                    }

                    img {}
                }

                &__text {
                    font-size: 16px;
                }
            }
        }

        &-btitle {font-size: 19px;margin-top: 6px;
        }

        &-bsubtitle {}
    }
}

@media (max-width: 767px) {
    .newlife {
        .title br{
            display: none;
        }
        &-content {
            margin-top: 14px;

            &-box {
                  width: 50%;
                  margin-bottom: 10px;  
                &__img {
                    &:after {

                        bottom: 9px;
                        font-size: 44px;

                        width: 42px;
                        height: 42px;

                    }

                    img {}
                }

                &__text {
                    font-size: 16px;
                }
            }
        }

        &-btitle {font-size: 19px;margin-top: 14px;
        }

        &-bsubtitle {}
        &-form{
            width: 460px;
						margin-top: 25px;
						padding-left: 200px;
            &-pack{
								left: 20px;
								width: 190px;
								
            }
        }
    }

}


@media screen and (max-width: 639px) {
    .newlife {
        &-content {
            margin-top: 14px;

            &-box {
                  width: 50%;
                  margin-bottom: 10px;  
                &__img {
                    &:after {

                        bottom: 9px;
                        font-size: 44px;

                        width: 42px;
                        height: 42px;

                    }

                    img {}
                }

                &__text {
                    font-size: 15px;
                }
            }
        }

        &-btitle {font-size: 18px;margin-top: 6px;
        }

        &-bsubtitle {width: 316px;
    font-size: 20px; max-width: 100%; border: 3px solid #fff;}
    }
}
@media screen and (max-width: 479px) {
    .newlife {
        &-content {
            

            &-box {
                  width: 100%;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                &__img {
                   width: 350px;
                    max-width: 100%;
                    
                }

                &__text {
                    max-width: 330px;
                }
            }
        }

        &-btitle {margin-top: 18px;
        }

        &-bsubtitle {}
        &-form{
            width: 290px;
            margin-top: 25px;
            padding-left: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            &-pack{
                position: initial;
                transform: translate(0,0);
            }
        }
    }
}