.problem {
	padding-top: 151px;
	padding-bottom: 92px;
	position: relative;
	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: -113px;
		left: 50%;
		height: 0px;
		width: 0px;
		border: 40px solid transparent;
		border-top: 30px solid #fff;
		z-index: 10;
		transform: translateX(-50%) translateY(-50%) ;
	}
	.title {
		width: 60%;
		margin-left: auto;
		margin-right: auto;
	}

	&__list {
		margin-top: 32px;
		display: flex;
		justify-content: flex-start;
		margin-left: -14px;
	}
	&__item {
		text-align: center; 
	}
	&__img {
			position: relative;
			width: 300px;
			height: 280px;
			overflow: hidden;
			padding-top: 20px;
			margin-left: auto;
			margin-right: auto;
		img {
			width: 271px;
			
			box-shadow: -1px 3px 24px rgba(181, 205, 223, 0.77);
			border-radius: 4px;
		}
	}
	&__circle {
		position: absolute;
		bottom: 22px;
		width: 260px;
		height: 68px;
		left: 49%;
		transform: translateX(-50%);
		z-index: 10;
		margin-left: auto;margin-right: auto;
		overflow: hidden;
		&::before {
				content: '';
				display: block;
				position: absolute;
				width: 810px;
				height: 810px;
				border-radius: 50%;
				background-color: #fff;
				z-index: 10;
				top: -379px;
				left: 50%;
				transform: translateY(50%) translateX(-50%);
				box-shadow: inset -1px 3px 24px rgba(181, 205, 223, 0.77);
			}
	}

	&__desc {
		margin-top: -5px;
		position: relative;
		font-size: 20px;
		line-height: 1.3;
	}

	&__count {
		background-color: #ffd36e;
		display: block;
		width: 52px;
		height: 52px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 57px;
		color: #2a3d70;
		font-weight: bold;
		position: absolute;
		top: -74px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
	}

	&__number {
		position: absolute;
		bottom: -20px;
		left: 50%;
    	transform: translateX(-50%);
	}

	&__text {
		padding: 0 10px;
	}
	&__bottom {
		margin-top: 70px;
		font-size: 24px;
		font-weight: 500;
		color: #202020;
		background-color: #e8f3da;
		text-align: center;
		max-width: 840px;
		margin-left: auto;	
		margin-right: auto;
		padding: 15px;
		border-radius: 60px;

	}
	
}


// media 

@media screen and (max-width: 1190px) {
	.problem {
		padding-bottom: 42px;
		padding-top: 80px;
		&__list {
			justify-content: space-between;
			flex-wrap: wrap;
			margin-left: -18px;
			padding: 0 10px;
			margin-top: 14px;
		}
		&__item {
			width: 230px;
		}
		&__img {
			width: 250px;
			height: 230px;
			img {
				width: 230px;
			}
		}

		&__circle {
			width: 224px;
			height: 50px;
			bottom: -6px;
			&::before {
				top: -403px;
			}
		}
		&__desc {
			margin-top: 0;
			font-size: 17px;

		}
		&__text {
			padding: 0;
		}
		&__count {
			top: -59px;
			height: 45px;
			width: 45px;
			font-size: 52px;
		}
		&__bottom {
			margin-top: 40px;
			font-size: 22px;
			max-width: 780px;
		}

	}
}

@media screen and (max-width: 1023px) {
	.problem {
		padding-top: 118px;
		padding-bottom: 40px;
		&::after {
			display: none;
		}

		.title {
			width: 80%;
		}

		&__list {
			justify-content: space-around;
			margin-left: 0;
			padding: 0;
		}
		&__item {
			width: 42%;
		}
		&__img {
			width: 300px;
			height: 280px;

			img {
				width: 270px;
			}
		}

		&__circle {
			width: 260px;
			height: 50px;
			bottom: 17px;
			&::before {
				top: -403px;
			}
		}
		&__desc {
			z-index: 20;
			margin-top: -26px;
		}
		&__bottom {
			font-size: 20px;
			max-width: 696px;
		}
	}
}

@media screen and (max-width: 767px) {
	.problem {
		padding-bottom: 30px;
		padding-top: 80px;
		
		.title {
			width: 90%;
		}
		&__list {
			margin-top: 6px;
		}
		&__item {
			width: 260px;
		}
		&__img {
			width: 250px;
			height: 230px;
			img {
				width: 230px;
			}
		}

		&__circle {
			width: 224px;
			height: 50px;
			bottom: -6px;
			&::before {
				top: -403px;
			}
		}
		&__desc {
			margin-top: -11px;
		}
		&__text {
			line-height: 1.2;
		}
		&__bottom {
			font-size: 18px;
			margin-top: 20px;
		}
	}
}

@media screen and (max-width: 639px) {
	.problem {
		padding-top: 25px;
		padding-bottom: 25px;
		.title {
			width: auto;
		}

		&__text {
			font-size: 16px;
		}
		&__bottom {
			font-size: 17px;
		}
	}
}