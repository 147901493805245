.header {
	background-color: #a9daea;
	background-image: url(../img/header-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	padding-top: 39px;
	position: relative;

	&::after {
		content: "";
	    position: absolute;
	    left: 50%;
	    
	    transform: translate(-50%) ;
	    bottom: -2px;
	    height: 20px;
	    width: 2660px;
	    background: url(../img/after-bg.svg) no-repeat center;
	    z-index: 5;
	}


	.container {
		position: relative;
		padding-bottom: 74px;
	}

	&__links {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 54px;
	}
	&__link {
		padding-left: 50px;
		position: relative;
		font-size: 17px;
		color: #444;
		max-width: 370px;
		// display: flex;
		// align-items: center;

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			background-size: contain;
			background-repeat: no-repeat;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			height: 94%;
			width: 1px;
			background-color: #b1d7e4;
			right: 44px;
			top: 3px;
		}

	}

	&__link-1 {
		margin-right: 33px;
	}

	&__link-2 {
		margin-right: 115px;
	}

	&__link-3 {
		padding-left: 58px;
		display: flex;
		align-items: center;
	}

	&__link-1::before {
		width: 34px;
		height: 37px;
		background-image: url(../img/header__link-1.png);
	}
	&__link-2::before {
		width: 38px;
		height: 38px;
		background-image: url(../img/header__link-2.png);
	}
	&__link-3::before {
		width: 41px;
		height: 33px;
		background-image: url(../img/header__link-3.png);
	}

	&__link-2::after {
		right: -47px;
	}

	&__link-3::after {
		display: none;
	}

	&__dashed {
		display: inline-block;
		line-height: 1.1;
		border-bottom: 1px dashed #83969d;
		width: auto;
		margin-top: 3px;

	}

	&__link a:hover &__dashed {
		border-bottom: none;
		
	}

	&__flex {
		display: flex;
	}

	&__about {
		max-width: 460px;
	}

	&__name-prod {
		font-size: 39px;
	}

	&__title {
		font-size: 50px;
		font-weight: bold;
		color: #202020;
		margin-top: 19px;
		line-height: 0.92;
	}
	&__subtitle {
		font-size: 21px;
		color: #444;
		line-height: 1.34;
		margin-top: 18px;
		max-width: 336px;
	}

	&__list {
		margin-top: 51px;
	}

	&__feature {
		position: relative;
		padding-left: 50px;
		font-size: 20px;
		color: #444;
		line-height: 1.14;
		margin-bottom: 22px;

		b {
			color: #202020;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: -10px;
		}
		&::before {
			
			height: 34px;
			width: 34px;
			border-radius: 50%;
			background-color: #edb440;
			box-shadow: 0px 3px 7px rgba(176, 186, 104, 0.15);
		}
		&::after {
			width: 19px;
			height: 10px;
			border: 4px solid #fff;
			border-right: none;
			border-top: none;
			left: 5px;
			top: -2px;
			transform:  rotate(-45deg);
		}
	}

	&__img {
		position: relative;
		height: 470px;
		width: 370px;
		align-self: flex-end;
		z-index: 10;
	
	}
	&__prod {
		position: absolute;
		height: auto;
		    width: 323px;
    top: 182px;
    left: -22px;
	}

	&__woman {
		height: auto;
		width: 554px;
		position: absolute;
		bottom: 14px;
		left: 59%;
		transform: translateX(-50%);
		z-index: 0;
	}

	.form {
		margin-top: 10px;
	}

	.form__pay-after {
		position: absolute;
		bottom: -160px;
		right: 0;
		text-align: left;
		background-color: #fff2d4;
		font-size: 18px;
		font-weight: 500;
		color: #202020;
		line-height: 1.16;
		width: 303px;
		display: flex;
		align-items: center;
		min-height: 56px;
		padding-left: 91px;
		padding-right: 40px;
		border-radius: 30px;
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 31px;
			height: 42px;
			width: 42px;
			background-image: url(../img/form__pay-after.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
		
	}

}

// for IE 10+ 
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.header {
		&__about {
			max-width: 900px;
			width: 470px;
		}
		.form__pay-after {
			height: auto;
			min-height: auto;
			padding-top: 5px;
			padding-bottom: 6px;
			&::before {
				top: 5px;
			}
		}

		&__list {
			width: 460px;
		}
	}
}


// media

@media screen and (max-width: 1280px) {
	.header {
		.form::after {
			display: none;
		}
	}
}

@media screen and (max-width: 1190px) {
	.header {
		&__links {
			// justify-content: space-around;
			justify-content: flex-start;
		}
		&__link-1 {
			margin-right: 0px;
			max-width: 320px;
			&::after {
				// right: 24px;
				right: 34px;
			}
		}
		&__link-2 {
			margin-right: 40px;
			&::after {
				right: -20px;
			}
		}

		&__flex {
			flex-wrap: wrap;
		}

		&__about {
			max-width: 420px;
		}
		&__title {
			font-size: 46px;
		}

		&__img {
			width: 270px;
		}

		&__prod {
			width: 270px;
			left: -20px;
			top: 205px;
		}

		&__woman {
			width: 520px;
		}
		.form::after {
			display: block;
		}
		.form__pay-after {
			bottom: -125px;
		}

	}
}


@media screen and (max-width: 1110px) {
	.header {
		.form::after {
			display: none;
		}
	}
}

@media screen and (max-width: 1023px) {
	.header {
		&::after {
			display: none;
		}
		.container {
			padding-bottom: 40px;
		}
		&__links {
			display: block;
			margin-bottom: 20px;
			
		}
		&__link {
			margin-bottom: 20px;
			display: none;
			margin: 0;
		}
		&__link-3 {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 220px;
		}

		&__about {
			width: 100%;
			max-width: none;
		}
		&__name-prod {
			display: block;
			text-align: center;
			font-size: 36px;
		}
		&__title {
			text-align: center;
			font-size: 42px;
			margin-top: 6px;
		}
		&__subtitle {
			text-align: center;
			max-width: none;
			margin-top: 10px;
			width: 76%;
			margin-left: auto;
			margin-right: auto;
		}

		&__list {
			margin-top: 20px;
			display: flex;
			justify-content: center;
			
		}

		&__feature {
			padding-left: 0;
			font-size: 19px;
			text-align: center;
			max-width: 211px;
			padding-top: 36px;
			&:nth-of-type(2) {
				max-width: 235px;
			}
			b {
				display: block;
			}
			&::before {
				left: 50%;
				top: 0px;
				transform:  translateX(-50%);
			}
			&::after {
				left: 50%;
				top: 8px;
				transform:  translateX(-50%) rotate(-45deg);
			}
		}

		&__woman {
			bottom: 0;
			transform: none;
			left: 0;
			transform: scaleX(-1);
			width: 490px;

		}
		&__img {
			margin-left: auto;
		}
		&__prod {
			left: 0;
		}
		.form__pay-after {
			bottom: -108px;
		}
	}
}

@media (max-width: 767px) {
	.header {
		&__name-prod {
			font-size: 34px;
		}

		&__title {
			font-size: 38px;
		}
		&__subtitle {
			font-size: 19px;
		}
		&__list {
			margin-top: 10px;
		}
		&__feature {
			font-size: 18px;
			padding-top: 29px;
			&::before {
				width: 28px;
				height: 28px;
			}

			&::after {
				width: 16px;
				height: 8px;
				border-width: 3px;
				top: 6px;
			}
		}
		&__woman {
			left: -120px;
		}
		&__prod {
			width: 230px;
			top: 222px;
			left: -10px;
		}
		.form__pay-after {
			bottom: -73px;
		}
	}
}

@media screen and (max-width: 639px) {
	.header {
		.container {
			padding-bottom: 106px; 
		}
		&__links {
			margin-bottom: 10px;
		}
		&__link-3 {
			padding-left: 0;
			text-align: center;
			&::before {
				left: 50%;
				top: -24px;
				transform: translateX(-50%);
				width: 30px;
				height: 30px;
			}
		}
		&__name-prod {
			font-size: 30px;
		}
		&__title {
			font-size: 33px;
		}
		&__subtitle {
			font-size: 17px;
			width: 100%;
		}
		&__list {
			display: block;
			margin-top: 10px;
			max-width: 330px;
			margin-left: auto;
			margin-right: auto;
		}
		&__feature {
			text-align: left;
			max-width: none;
			margin: 0;
			font-size: 16px;
			padding-top: 0;
			padding-left: 30px;
			margin-bottom: 10px;
			&:nth-of-type(2) {
				max-width: none;
			}
			&::before {
				left: 10px;
			}
			&::after {
				left: 10px;
			}
		}
		&__img {
			height: auto;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			margin-top: 20px;
		}
		&__prod {
			position: static;
			width: 60%;
			min-width: 200px;
		}
		&__woman {
			display: none;
		}
		.form {
			margin-left: auto;
			margin-right: auto;
		}
		.form__pay-after {
			transform: translateX(-50%);
			left: 50%;
		}
	}
}