// desctop
.footer {
  padding-top: 115px;
  padding-bottom: 40px;
  color: #757575;
  font-size: 17px;
  position: relative;
}


.footer__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__logo {
  width: 40px;
  height: 49px;
  margin-right: 23px;
}

.footer__prod {
line-height: 24px;
}

.footer__privacy {
  display: block;
   color: #757575;
   text-decoration: underline;
line-height: 24px;

  &:hover {
    text-decoration: none;
  }
}

.footer__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer__center {
  text-align: center;
}

.footer__right {
  text-align: right;
  text-transform: uppercase;
}

.footer__numbers {
line-height: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}


// media
@media screen and (max-width: 1190px) {
  .footer {
    padding-top: 70px;
  }

}

@media screen and (max-width: 1023px) {
  .footer {
    font-size: 16px;
    padding-top: 40px;
  }
  .footer__logo {
    margin-right: 10px;
  }

}

@media screen and (max-width: 767px) {
  .footer {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .footer__right {
    display: none;
  }

  .footer__name {
    display: none;
  }

  .footer__adress {
    display: none;
  }

  .footer__wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer__logo {
    margin-right: 0;
    margin-bottom: 10px;
  }

}
