.reviews {
    position: relative;
   margin-top: -30px;
    padding-top: 94px;
    padding-bottom: 120px;
    background: url('../img/bg.png') no-repeat 50% 0;
    background-color: #e4f3f9;
    position: relative;
    background-size: cover;
    z-index: 1;

     &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%) rotate(-180deg);
        top: -1px;
        height: 20px;
        width: 2660px;
        background: url(../img/after-bg.svg) no-repeat center;
        z-index: -1;
    }

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
        height: 20px;
        width: 2660px;
        background: url(../img/after-bg.svg) no-repeat center;
        z-index: -1;
    }

    &-content {
        margin-top: 63px;

        &-box {
            display: flex !important;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-bottom: 46px;
            margin-bottom: 100px;

            background-color: #fff;
            box-shadow: -2px 5px 21px rgba(181, 205, 223, 0.77);
            position: relative;
            z-index: 50;

            &__img {
                position: relative;
                margin-top: -44px;
            }

            &__name {
                margin-top: 10px;
                color: #202020;
                font-size: 23px;
                font-weight: 500;
                line-height: 1;
            }

            &__thesis {
                background-color: #f7d373;
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 75px;
                color: #202020;
                font-size: 18px;
                font-style: italic;
                line-height: 24px;
                padding: 0 20px;
                position: relative;
                margin-top: 30px;

                &:before {
                    content: '“';
                    position: absolute;
                    left: 50%;
                    top: 4px;
                    font-style: normal;
                    transform: translate(-50%);
                    color: #2a3d70;
                    font-size: 53px;
                    font-weight: 500;

                }
            }

            &__text {
                margin-top: 27px;
                padding: 0 20px;
                color: #444444;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                max-height: 303px;
                overflow: hidden;
                transition: all 1s;
                width: 370px;
                  max-width: 100%;
            }

            &__btn {

                position: absolute;
                bottom: -22px;
                left: 50%;
                transform: translate(-50%);
                transition: all .5s;
                cursor: pointer;
            }

            &__cbtn {
                color: #fff;
                width: 45px;
                height: 44px;
                background-color: #82aa34;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                transition: all .5s;

                &:hover {
                    background-color: #c2df89;
                }
            }

        }
    }

}



.content-plus .reviews-content-box__btn {
    transform: translate(-50%) rotate(180deg);
}

.slick-slide {
    margin: 0 15px;
    margin-top: 45px;
    margin-bottom: 25px;
}

.slick-list {
    margin: 0 -15px;
}

.slick-prev {
    font-size: 0;
    width: 41px;
    height: 41px;
    background-color: #ffd36e;
    left: -60px;
    border-radius: 50%;
    transition: all .5s;

    &:before {
        font-size: 0;
        width: 2px;
        height: 12px;
        background-color: #2a3d70;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-75%, -78%) rotate(45deg);
        transition: all .5s;
    }

    &:after {
        content: "";
        font-size: 0;
        width: 2px;
        height: 12px;
        background-color: #2a3d70;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-75%, -13%) rotate(-45deg);
        transition: all .5s;
    }

    &:hover,
    &:focus {
        background-color: #ffdb88;
    }
}

.slick-next {
    font-size: 0;
    width: 41px;
    height: 41px;
    background-color: #ffd36e;
    right: -60px;
    border-radius: 50%;
    transition: all .5s;

    &:before {
        font-size: 0;
        width: 2px;
        height: 12px;
        background-color: #2a3d70;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(75%, -75%) rotate(-45deg);
        transition: all .5s;
    }

    &:after {
        content: "";
        font-size: 0;
        width: 2px;
        height: 12px;
        background-color: #2a3d70;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(75%, -14%) rotate(45deg);

    }

    &:hover,
    &:focus {
        background-color: #ffdb88;

    }
}

@media screen and (max-width: 1345px) {
    .slick-next {
        right: 0px;
    }

    .slick-prev {
        left: 0px;
    }

    .slick-list {
        margin: 0 40px;
    }
}

@media screen and (max-width: 1190px) {
    .reviews {

        &-content {
            margin-top: 45px;

            &-box {
                &__name {
                    font-size: 21px;
                }

                &__thesis {
                    font-size: 16px;
                    line-height: normal;

                    &:before {
                        line-height: 1;
                        top: -11px;
                    }
                }

                &__text {
                    font-size: 16px;
                    padding-left: 12px;
                    padding-right: 12px;
                    line-height: 23px;
                    margin-top: 23px;
                    max-height: 296px;
                  width: 278px;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .reviews {
        margin-top: 0;

        &:before,
        &:after {
            display: none;
        }

        &-content {

            &-box {
                &__name {}

                &__thesis {

                    &:before {}
                }

                &__text {
width: 304px;
max-height: 303px;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .reviews {
        &-content {
            margin-bottom: 50px;

            &-box {
                &__name {
                    font-size: 18px;
                }

                &__thesis {
                    margin-top: 20px;
                    padding: 10px 15px;
                    min-height: 60px;
                    height: auto;
                    &:before {}
                }

                &__text {
width: 295px;
                    margin-top: 10px;
                }
            }
        }
    }

    .slick-next {
        right: 8px;
        transform: translate(0, -0%);
        z-index: 10;
        top: 193px;

    }

    .slick-prev {
        left: 8px;
        transform: translate(0, 0%);
        z-index: 10;
        top: 193px;

    }

    .slick-list {
        margin: 0 -15px;
    }
}

@media screen and (max-width: 639px) {
    .reviews {
        &-content {
            margin-bottom: 0;
            width: 370px !important;
            max-width: 100%;
            margin-right: auto;
            margin-left: auto;

            &-box {


                &__name {}

                &__thesis {

                    &:before {}
                }

                &__text {
                    max-height: 188px;
                    width: 100%;
                }
            }
        }
    }


    .slick-list {
        margin: 0 -15px;
    }
}

.content-plus .reviews-content-box__text {
    max-height: 800px;
}